export const REQUIRED_FIELD_MESSAGE = 'Wajib diisi';
export const INVALID_EMAIL_MESSAGE = 'Email tidak sesuai format';
export const INVALID_FIRST_PHONE_NUMBER =
	'Nomor telepon harus diawali dengan 08';
export const EKYC_INVALID_FIRST_PHONE_NUMBER =
	'Nomor telepon harus diawali dengan 8';
export const INVALID_MINIMUM_PHONE_NUMBER =
	'Nomor telepon minimal harus 9 digit';
export const EKYC_INVALID_MINIMUM_PHONE_NUMBER =
	'Nomor telepon minimal harus 8 digit';
export const GENERAL_ERROR_MESSAGE =
	'Gagal mengambil data. Silahkan coba lagi.';
export const ERROR_CODE_MESSAGE: { [key: string]: string } = {
	6001: 'Akun dengan email tersebut sudah terdaftar.',
};
export const DELETE_USER_ERROR_MESSAGE = 'Gagal menghapus akun.';
