import { useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { Button, Typography } from '@squantumengine/horizon';
import { useProfileStore } from 'common/stores/profile';
import SpSkeleton from 'common/components/sp-skeleton';
import type { SectionHeaderProps } from './section-header.interfaces';

const { Title } = Typography;

const SectionHeader = ({
	buttonText,
	backUrl,
	children,
	containerClass,
	isLoading = false,
	title,
	backHandler,
	onActionClick,
}: SectionHeaderProps) => {
	const navigate = useNavigate();
	const email = useProfileStore((state) => state.email);

	const onBackActionClick = () => {
		if (backHandler) return backHandler();
		backUrl && navigate(backUrl);
	};

	const containerClassNames = `flex justify-between items-center ${containerClass}`;

	const onEditPermission = () => {
		window.location.href = `${
			process.env.REACT_APP_SQE_ID_EDIT_PERMISSION_URL
		}?client_id=${process.env.REACT_APP_SQE_ID_CLIENT_ID}&redirect_uri=${
			window.location.href
		}&username=${encodeURIComponent(email)}`;
	};
	return (
		<div className={containerClassNames}>
			<div className="flex">
				{backUrl || backHandler ? (
					<div
						className="flex justify-center items-center w-8 h-8 border-solid border border-neutral-100 rounded mr-6 cursor-pointer"
						onClick={onBackActionClick}
					>
						<ArrowLeftOutlined aria-label="Back icon"></ArrowLeftOutlined>
					</div>
				) : null}
				{isLoading ? (
					<SpSkeleton className="w-[230px] h-8"></SpSkeleton>
				) : (
					<Title level={5} className="leading-normal">
						{title}
					</Title>
				)}
			</div>
			{children}
			{!children && buttonText && onActionClick && (
				<div className="flex gap-4">
					<Button variant="secondary" onClick={onEditPermission}>
						Edit Permission
					</Button>
					<Button onClick={onActionClick}>{buttonText}</Button>
				</div>
			)}
		</div>
	);
};

export default SectionHeader;
